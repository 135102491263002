import React, { Component } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../BreadcrumbComponent/BreadcrumbComponent";
// import Image from "./ImageComponent/ImageComponent";
import Image from "./ImageComponent3/ImageComponent";
import Image2 from "./ImageComponent2/ImageComponent2";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import HeaderTitle from "../HeaderComponents/HeaderTitle";
import { Helmet } from "react-helmet";
import "./AboutUsStyles.css";
import { Zoom, Fade } from "react-reveal";

class About4 extends Component {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
    responsive2: {
      0: {
        items: 1,
        dots: false,
        dotsEach: false,
      },
      600: {
        items: 1,
        dots: false,
        dotsEach: false,
      },
      768: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  render() {
    return (
      <React.Fragment>
        <HeaderTitle heading="ABOUT THE CLUB" image="about-us.jpg" />
        <Helmet>
          <title>About The Club | TechnoHub BITD</title>
        </Helmet>
        <div className="aboutus-container">
          <Breadcrumb>
            <BreadcrumbItem icon="fas fa-home" title="Home" path="/" />
            <BreadcrumbItem
              icon="fas fa-info-circle"
              title="About Us"
              status="active"
            />
          </Breadcrumb>
          <div className="container">
            <div className="row row-content align-items-center justify-content-center">
              <Fade>
                <div className="col-12 col-md-4">
                  <img
                    src="./assets/images/logo.png"
                    height="150"
                    width="260"
                    alt="Technohub"
                  />
                </div>
              </Fade>
              <Zoom>
                <div className="col-12 col-md-8">
                  <h2>TechnoHub</h2>
                  <br />
                  <p className="aboutText">
                    The club provides an ambiance for students to prepare and to
                    showcase their skills at technical events and
                    entrepreneurship held all across India and Asia in various
                    colleges with the aim to add more feathers in the cap. The
                    ultimate goal of the TechnoHub club is to educate more
                    students and make robotics and entrepreneurship an everyday
                    phenomenon. The focus of the TechnoHub club primarily is to
                    help the students gain practical as well as theoretical
                    technical knowledge. The Journey started with a mere some 4
                    members and today it is a colossal family of 250+ members.
                  </p>
                </div>
              </Zoom>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Training & Placement Officer</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel className="owl-theme" items={1}>
                <Fade up>
                  <Image
                    path="./assets/images/core22/manisha.jpg"
                    name="Dr. Manisha Sharma"
                    post="Head"
                    social={false}
                  >
                    TPO Head
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Teacher Incharge</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel className="owl-theme" items={1}>
                <Fade up>
                  <Image
                    path="./assets/images/core22/Shubhrata_nagpal.jpg"
                    name="Shubhrata Nagpal"
                    post="Incharge"
                    social={false}
                  >
                    Teacher Incharge
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>President</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel className="owl-theme" items={1}>
                <Fade up>
                  <Image
                    path="./assets/images/core23/Manish.jpeg"
                    name="Gude Manish"
                    post="President"
                    contact="8085311153"
                    social={true}
                    linkedin="https://www.linkedin.com/in/gude-manish-kumar-486576261/"
                    instagram="https://www.instagram.com/___mnsh__/"
                    email="mkgude2003@gmail.com"
                  >
                    Mechanical
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Vice President</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel className="owl-theme" items={1}>
                <Fade up>
                  <Image
                    path="./assets/images/core23/Shubhra.jpg"
                    name="Shubhra Dubey"
                    post="Vice President"
                    contact="9179369588"
                    social={true}
                    instagram="https://www.instagram.com/30_uknowwho?igsh=YzQzNWFvd2J2dzc="
                    linkedin="https://www.linkedin.com/in/shubhra-dubey-112706255?utm_source=share&utm_campaign=share_via&utm_content"
                    twitter="https://x.com/shubhrad30?t=fWe4kXtFmZAFNXdrKhaaTA&s=09"
                  >
                   Information Technology
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
            <div className="row">
              <OwlCarousel className="owl-theme" items={1}>
                <Fade up>
                  <Image
                    path="./assets/images/core23/Siddharth.JPG"
                    name="Siddharth Soni "
                    post="Vice President"
                    contact="6264629378"
                    social={true}
                    linkedin="https://www.linkedin.com/in/siddharth-soni-84aa5b225?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    github="https://github.com/Sidgithub07"
                    instagram="https://www.instagram.com/siddharth130603?utm_source=qr&igsh=a3N2YXQ2NW80M28z"
                  >
                   Information Technology
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>
          {/* General Manager Start */}
          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>General Managers</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel
                className="owl-theme"
                responsive={this.state.responsive2}
              >
                <Fade up>
                  <Image
                    path="./assets/images/core23/Abhishek.jpeg"
                    name="Abhishek Neogi"
                    post="General Manager"
                    contact="9981779791"
                    social={true}
                    linkedin="https://www.linkedin.com/in/abhishek-neogi-472783228/"
                    github="https://github.com/AbhishekNeogi"
                    instagram="https://www.instagram.com/__.abhishek.__n/"
                  >
                    Computer Science
                  </Image>

                  <Image
                    path="./assets/images/core23/Devansh.jpeg"
                    name="Devansh Shrivastava"
                    post="General Manager"
                    contact="6232275431"
                    social={true}
                    linkedin="https://www.linkedin.com/in/devansh-shrivastava-94228a230/"
                    github="https://github.com/devansh0331"
                    instagram="https://www.instagram.com/devansh19_official/"
                    
                  >
                    Computer Science
                  </Image>
                  <Image
                    path="./assets/images/core23/Anushka.jpg"
                    name="Anushka Bhandarkar"
                    post="General Manager"
                    contact="7587168936"
                    social={true}
                    linkedin="https://www.linkedin.com/in/anushka-bhandarkar-21094622a"
                    github="https://github.com/anushkab2508"
                    instagram="https://www.instagram.com/nush_2508?igsh=aWl4enpteTlmbmw1"
                    
                  >
                    Computer Science
                  </Image>
                  <Image
                    path="./assets/images/core23/Bhumika.jpg"
                    name="Bhumika Sahu"
                    post="General Manager"
                    contact="626495731"
                    social={true}
                    linkedin="https://www.linkedin.com/in/bhumikasahu03/"
                    github="https://github.com/Bhumika987"
                    instagram="https://www.instagram.com/_._bhumi_/"
                  >
                    Computer Science
                  </Image>
                  <Image
                    path="./assets/images/core23/Sitanshu.png"
                    name="Sitanshu Lall"
                    post="General Manager"
                    contact="6263375079"
                    social={true}
                    linkedin="linkedin.com/in/sitanshulall"
                    twitter="https://twitter.com/SitanshuLall"
                    github="https://github.com/SitanshuLall"
                    
                  >
                    Computer Science
                  </Image>
                  <Image
                    path="./assets/images/core24/Ahmed.JPG"
                    name="Ahmed Inamuddin"
                    post="General Manager"
                    contact="7869770640"
                    social={true}
                    email= "11.20.2002.ahmed@gmail.com"
                    linkedin="https://www.linkedin.com/in/ahmed-inamuddin-27a216264?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                    instagram="https://www.instagram.com/ahmxd0640/profilecard/?igsh=MzlvMzE3ZmlleHp5"
                    
                    
                  >
                    Computer Science
                  </Image>
                  
                </Fade>
              </OwlCarousel>
            </div>
          </div>
          {/* General Manager End */}

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Program Managers</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel
                className="owl-theme"
                responsive={this.state.responsive2}
              >
                <Fade up>
                  <Image
                    path="./assets/images/core24/Khyati_Jain_Program_Manager.jpg"
                    name="Khyati Jain"
                    post="Program Manager"
                    contact="9479541607"
                    social={true}
                    email="khyatijain244@gmail.com"
                    linkedin="https://www.linkedin.com/in/khyati-jain-053344296?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    instagram="-https://www.instagram.com/_khyati_10?igsh=MWk1cHBtc2hxdDFibg=="
                    
                  >
                    Information Technology
                  </Image>

                  <Image
                    path="./assets/images/core24/Sarthak_Kumar_Sinha_Associate_Program_Manager.jpg"
                    name="Sarthak Kumar Sinha"
                    post="Associate Program Manager"
                    contact="7646920887"
                    social={true}
                    email="sarthaksinha.s.k.s@gmail.com"
                    linkedin=" https://www.linkedin.com/in/sarthak-kumar-sinha?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    
                  >
                    Computer Science
                  </Image>
                  <Image
                    path="./assets/images/core24/Thomson_Mathews_Public_Relations_Head (1).jpg"
                    name="Thomson Mathews"
                    post="PR Head"
                    contact="8965979195"
                    social={true}
                    email="thomsonmat1105@gmail.com"
                    linkedin=" https://www.linkedin.com/in/thomson-mathews-6b014b197/"
                    instagram="https://www.instagram.com/mathews_thomson/"
                  >
                    Computer Science
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>

          {/* Project Managers start */}
          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Project Managers</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel
                className="owl-theme"
                responsive={this.state.responsive2}
              >
                <Fade up>
                  <Image
                    path="./assets/images/core24/Shashank_Mishra_Finance_Head.jpg"
                    name="Shashank Mishra"
                    post="Finance Head"
                    contact="9343515832"
                    social={true}
                    email="mishrashashank2106@gmail.com"
                    linkedin="https://www.linkedin.com/in/shashank-mishra-05590b26b"
                    instagram="https://www.instagram.com/mishrashashank__?igsh=dXoxNjIwZ2JlZzN4"
                  >
                    Computer Science
                  </Image>

                  <Image
                    path="./assets/images/core24/preeti.jpeg"
                    name="Preeti Bhattacharya"
                    post="Technical Head"
                    contact="9131901569"
                    social={true}
                    email="preetibhattacharya13@gmaiol.com"
                    linkedin="https://www.linkedin.com/in/preeti-bhattacharya-627986270/"
                    github="https://github.com/preetibhattacharya"
                    
            
                  >
                    Computer Science
                  </Image>
                  <Image
                    path="./assets/images/core24/Pranshu_Sahu_Technical_Head.jpg"
                    name="Pranshu Sahu"
                    post="Technical Head"
                    contact="7770914745"
                    social={true}
                    email="workspace.pranshu@gmail.com"
                    linkedin="www.linkedin.com/in/pranshu-sahu-2178b9248"
                    github="https://github.com/Praanshuu"
                    
            
                  >
                    Computer Science
                  </Image>

                  <Image
                    path="./assets/images/core24/Swara_Pudke_Social_Media_Head.jpg"
                    name="Swara Pudke"
                    post="Social Media Head"
                    contact="9301816311"
                    social={true}
                    email="pudkeswara29@gmail.com"
                    linkedin="https://www.linkedin.com/in/swara-pudke-645a5825a"
                    instagram="https://www.instagram.com/__swaraa_/profilecard/?igsh=MXJ5MWYzOXcwcjllZA=="
                  >
                    Information Technology
                  </Image>
                  <Image
                    path="./assets/images/core24/Ruba_Abirami_social_media_Head.png"
                    name="Ruba Abirami"
                    post="Social Media Head"
                    contact="7449137571"
                    social={true}
                    email="rubaabhirami2004@gmail.com"
                    instagram="https://www.instagram.com/ruba_abirami/profilecard/?igsh=MTB6bnBsajhsaTF0MA=="
                  >
                    Computer Science
                  </Image>

                  <Image
                    path="./assets/images/core24/Gunjan_Kumar_Deshlahara_Graphics_Head.jpg"
                    name="Gunjan Kumar Deshlahara"
                    post="Graphics Head"
                    contact="7000946966"
                    social={true}
                    email="gunjandeshlahara1995@gmail.com"
                    linkedin="https://in.linkedin.com/in/gunjan-kumar-deshlahara"
                    instagram="https://www.instagram.com/ixg_gunjan?igsh=MXU4bnNzbnZ2azhlaA=="
                    
                  >
                    Computer Science
                  </Image>

                  <Image
                    path="./assets/images/core24/Anandita_Singh_Aptitude_head (1).jpg"
                    name="Anandita Singh"
                    post="Aptitude Head"
                    contact="7389500646"
                    social={true}
                    email="sanandita2003@gmail.com"
                    linkedin="https://in.linkedin.com/in/anandita-singh-21213b18b"
                    instagram="https://www.instagram.com/anandita_rajput_/profilecard/?igsh=NmMzZ3l5MmdyMGw5"
                    
                  >
                    Civil Engineering
                  </Image>
                  <Image
                    path="./assets/images/core24/Shriya_Singh_Content_Head.jpg"
                    name="Shriya Singh"
                    post="Content Head"
                    contact="8103257706"
                    social={false}
                    email="shriya400385@gmail.com"
                  >
                    Electronics and Communication
                  </Image>
                  <Image
                    path="./assets/images/core24/yogesh_banjare_video_design_Head.jpg"
                    name="Yogesh"
                    post="Video Design Head"
                    contact="9300950137"
                    social={true}
                    email="yogeshbanjare1307@gmail.com"
                    linkedin="https://www.linkedin.com/in/yogesh-banjare-80b36730b"
                    instagram="https://www.instagram.com/yb_nikku/profilecard/?igsh=Mmhob3hwY21pY3N5"
                  >
                    Computer Science
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>
          {/* Project Managers End */}

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Coordinators</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel
                className="owl-theme"
                responsive={this.state.responsive}
              >
                <Fade up>
                  <Image
                    path="./assets/images/core24/Ashish_Funde_co-ordinator.jpg"
                    name="Ashish Funde"
                    contact="9399073802"
                    social={true}
                    email="ashishfunde708@gmail.com"
                    linkedin="https://www.linkedin.com/in/ashish-funde-82986232b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    instagram="https://www.instagram.com/__ashish03_?igsh=OGl0OHFpZTAxeDhl"
            
                  >
                    Information Technology
                  </Image>
                  <Image
                    path="./assets/images/core24/Prashun_Mishra_co-ordinator.jpg"
                    name="Prashun Mishra"
                    contact="9893795821"
                    social={true}
                    email="mishraprashun47@gmail.com"
                    linkedin="https://www.linkedin.com/in/prashun-mishra-b98b9724b"
                    instagram="https://www.instagram.com/mprashun/profilecard/?igshDl=MWxkN3J4bmxrbXhyeg=="
                  >
                    Computer Science
                  </Image>
                  <Image
                    path="./assets/images/core24/srishti_kumari.jpg"
                    name="Srishti Kumari"
                    contact="9199244574"
                    social={true}
                    email="srishtikumari816@gmail.com"
                    linkedin="https://www.linkedin.com/in/srishti-kumari-psk816/"
                    instagram="https://www.instagram.com/srishti.816/profilecard/?igsh=MWJodmMxeHF1ZW9seQ=="
                    
                  >
                    Computer Science
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>

          {/* Secretaries start */}
          {/* <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Secretaries</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel
                className="owl-theme"
                autoplay
                autoplayTimeout={2000}
                autoplayHoverPause
                responsive={this.state.responsive2}
              >
                <Fade up></Fade>
              </OwlCarousel>
            </div>
          </div> */}
          {/* Secretaries End */}

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Our Vision</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row text-justify ml-1">
              <p>
                ‘IMAGINE INVENT INSPIRE ‘TechnoHub, revolves and works under
                this catchphrase. Think of society with the technical
                perspective and lead the society technically (technical
                startups) to spread awareness of technology. The most impressive
                thing about TechnoHub is that it believes in team work. Each and
                every session and workshop is consummate because of each and
                every individual’s contribution. It toils on the principle
                ‘IMAGINE INVENT INSPIRE ‘ where each and every individual is
                given equal opportunity . TechnoHub stands out from the rest of
                the clubs because of its assiduous members and constant guidance
                and support from the faculty members. It is a matter of
                amour-propre and repletion that TechnoHub has its own website
                wherein students get to know about the upcoming events as well
                as they get to interact with the seniors to collab with them
                having same kind of interest. Technical part of TechnoHub has
                60+ members working in the technical ground and take up projects
                and showcase their talent. Also compete with other splendid
                talent.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default About4;
