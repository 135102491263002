import React from 'react';
//import Sample from "../../../img/sample.png";
import PDF1 from "../../../pdf/1.pdf";
import "./JournalsCardComponent.css";

function JournalsCardComponent(props) {
  return (
    <div className="journalsCard">
      <center>
      <img src={props.img} alt="" />
      </center>
      <p>{props.category} / {props.time} mins read</p>
      <center className="card-cont" >
        <h2>{props.heading}</h2>
        <p>By {props.author}</p>
        <a target="__blank" href={props.pdf}>Read more</a>
      </center>
    </div>
  )
}

export default JournalsCardComponent;