import React from 'react'
import AlertModalComponent from '../../AlertModalComponent/AlertModalComponent';
import { useHistory } from "react-router-dom";

function ApticoTest() {
    let history = useHistory();
    return (
        <AlertModalComponent
          message="The result will be announced soon!!"
          icon="exclamation"
          leftBtn="Go to Home"
          rightBtn="View Tests"
          action={() => {
            history.push("/home");
          }}
          close={() => {
            history.push("/tests");
          }}
        />
    );
}

export default ApticoTest