import React, { useEffect } from "react";
import "./VoiceMessages.css";
import MaleAudio from "../../../audio/male.aac";
import FemaleAudio from "../../../audio/female.mp3";

const VoiceMessages = () => {
  useEffect(() => {
    const namesList = document.querySelector(".namesList");
    const names = document.querySelectorAll(".singleName");
    const selectedName = document.querySelector(".selectedName");
    const voiceCont = document.querySelector(".voiceCont");
    const audioElement = document.querySelector(".audioElement");
    const riddleCont = document.querySelector(".riddleCont");

    names.forEach((name) => {
      name.addEventListener("click", () => {
        namesList.classList.add("d-none");
        voiceCont.classList.remove("d-none");
        selectedName.innerText = name.innerText;

        audioElement.src = name.getAttribute("audio");
      });
    });

    const voiceBack = document.querySelector(".voiceBack");
    const voiceNext = document.querySelector(".voiceNext");

    voiceBack.addEventListener("click", () => {
      voiceCont.classList.add("d-none");
      namesList.classList.remove("d-none");
      selectedName.innerText = "Select your Name";

      audioElement.src = null;
    });

    voiceNext.addEventListener("click", () => {
      voiceCont.classList.add("d-none");
      riddleCont.classList.remove("d-none");
    });

    const riddleBack = document.querySelector(".riddleBack");

    riddleBack.addEventListener("click", () => {
      riddleCont.classList.add("d-none");
      voiceCont.classList.remove("d-none");
    });
  }, []);

  return (
    <React.Fragment>
      <div className="voiceMessagesCont">
        <h3 className="selectedName">Select your Name</h3>
        <div className="namesList">
          <div className="singleName" audio={MaleAudio}>
            Aaryan Khandelwal
          </div>
          <div className="singleName" audio={FemaleAudio}>
            Kritika Upadhyay
          </div>
        </div>
        <div className="voiceCont d-none">
          <h5>Listen to the audio carefully! It will help you later.</h5>
          <audio className="audioElement" controls>
            Your browser does not support the audio element. Kindly contact the
            team for help.
          </audio>
          <div className="controlBtns text-center">
            <button className="btn btn-danger voiceBack">Back</button>
            <button className="btn btn-primary voiceNext">Next</button>
          </div>
        </div>
        <div className="riddleCont d-none">
          <div className="riddleElement">
            <a href="https://webchat.snatchbot.me/76088575b37d2a8be4f2bc310dae89a7f6548a073b481e7d6db9f4b8108a71b0">
              Click here to continue
            </a>
          </div>
          <div className="controlBtns text-center">
            <button className="btn btn-danger riddleBack">Back</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VoiceMessages;
