import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import "./RollTheDice.css";
import Dice from "react-dice-roll";
import PDF1 from "../../../pdf/1.pdf";
import PDF2 from "../../../pdf/2.pdf";
import PDF3 from "../../../pdf/3.pdf";
import PDF4 from "../../../pdf/4.pdf";
import PDF5 from "../../../pdf/5.pdf";

function RollTheDice() {
  const [broCode, setBroCode] = useState("");
  const [levelCode, setLevelCode] = useState("1234");
  const [broPdf, setBroPdf] = useState("PDF1");
  const [broAudio, setBroAudio] = useState("Male");
  const [statuss, setStatus] = useState("");
  const [pdfToDownload, setToDownload] = useState(null);

  const [number, setNumber] = useState(0);
  useEffect(() => {
    const fetchdata = async () => {
      db.collection("winnersofrollthedice")
        .get()
        .then(function (querySnapshot) {
          setNumber(querySnapshot.size);
        });
    };
    fetchdata();
  }, []);

  // // firebase mai store karne ke liye
  // const firestoremaisave = (e) => {
  //   e.preventDefault();
  //   db.collection("rollthedice").doc(broCode).set({
  //     broCode: broCode,
  //     pdf: broPdf,
  //     audio: broAudio,
  //     statuss: false,
  //   });
  // };

  let [diceTotal, setTotal] = useState(0);
  const [firstDice, disableFirst] = useState(false);
  const [secondDice, disableSecond] = useState(false);
  let [diceCount, setCount] = useState(0);

  const riddles = [
    {
      number: 11,
      question:
        "I have keys but no lock. I can give you space but have no room. I love to wear caps but I have no head. I have the power to present both the real and virtual world. What am I?",
      answer: "keyboard",
    },
    {
      number: 12,
      question:
        "I am lighter than air but a hundred people cannot lift me. Careful, I am fragile. What am I?",
      answer: "bubble",
    },
    {
      number: 13,
      question:
        'What is black when you buy it, red when you use it, and gray when you throw it away.\n\nHint: "4 letters"',
      answer: "coal",
    },
    {
      number: 14,
      question:
        "I have cities but no houses, forests but no trees, water but no fish. What am I?",
      answer: "Map",
    },
    {
      number: 15,
      question:
        "I am at school or home. I have a mouse. You can use me for work or games. You can use it for an email I know! What am I?",
      answer: "Computer",
    },
    {
      number: 16,
      question:
        "Using a particular code ‘TREASURE’ is encrypted as ‘RTAEUSER’. What is the right code for ‘TECHNO’ ?",
      answer: "ETHCON",
    },
    {
      number: 21,
      question:
        'Decode the sentence given below ‘Vgejpqjwd’.\n\nHint: "A=C,P=Q,D=F Vjkpm=Think"',
      answer: "Technohub",
    },
    {
      number: 22,
      question:
        "Look at this series: 7, 10, 8, 11, 9, 12, … What number should come next?",
      answer: "10",
    },
    {
      number: 23,
      question:
        "Pointing to a photograph, a man said, 'I have no brother, and that man's father is my father's son' Whose photograph was it?",
      answer: "Son",
    },

    {
      number: 24,
      question:
        "Camping out? Keep me near. Although I cannot solve your fear. It just helps to flick my switch when in the dark you sense a twitch",
      answer: "Flashlight",
    },

    {
      number: 25,
      question:
        "I have no mouth and no nose, but I do have a face. I also have two hands and, but no legs and or toes.",
      answer: "clock",
    },
    {
      number: 26,
      question: "What has a neck and no head, two arms but no hands?",
      answer: "Shirt",
    },
    {
      number: 31,
      question: "You go to red and stop at green. What am I?",
      answer: "Watermelon",
    },
    {
      number: 32,
      question:
        "I have legs, and I have arms. I have a back, too. I am not alive. What am I?",
      answer: "skeleton",
    },
    {
      number: 33,
      question: "What runs, but never walks. Has a mouth, but never talks?",
      answer: "river",
    },
    {
      number: 34,
      question:
        "I am an object, I have four children and sixteen grandchildren. What am I?",
      answer: "Ludo",
    },
    {
      number: 35,
      question:
        "This is what you use to write But it is not a pen One of these has five fingers And two of them have ten",
      answer: "Hands",
    },
    {
      number: 36,
      question:
        "You can look through me As if I am not there. In summer I keep heat out, In winter I block cold air. I'm a?",
      answer: "Window",
    },
    {
      number: 41,
      question:
        "I am an 8 letter word. First 4 is the question. 2, 3, 4 protects our head. 5, 6, 7 IT company name. 7 & 8 are the same letters. Who am I?",
      answer: "Whatsapp",
    },
    {
      number: 42,
      question:
        "Consider this about a word: The first two letters signify a male, the first three letters signify a female, the first four letters signify a great, while the entire world signifies a great woman. What is the word?",
      answer: "Heroine",
    },
    {
      number: 43,
      question:
        "A red house is made out of red bricks. A yellow house is made out of yellow bricks. And, a blue house is made out of blue bricks. So, what is a greenhouse made of?",
      answer: "Glass",
    },
    {
      number: 44,
      question: "What goes up when rain comes down from the clouds?",
      answer: "Umbrella",
    },
    {
      number: 45,
      question: "Which word becomes shorter when you add 2 letters to it?",
      answer: "Short",
    },
    {
      number: 46,
      question:
        "I can be cracked, I can be made. I can be told, I can be played. What am I?",
      answer: "joke",
    },
    {
      number: 51,
      question: "How many months have 28 days?",
      answer: "Twelve",
    },
    {
      number: 52,
      question:
        "What comes once in a minute, twice in a moment, but never in a thousand years? ",
      answer: "M",
    },
    {
      number: 53,
      question: "I’m full of holes but strong as steel. What am I? ",
      answer: "chain ",
    },
    {
      number: 54,
      question: " What is it that given one, you’ll have either two or none?",
      answer: "choice ",
    },
    {
      number: 55,
      question:
        "A boy and a doctor were fishing. The boy is the doctor's son, but the doctor isn't the boy's father. Who is the doctor? ",
      answer: "Mother",
    },
    {
      number: 56,
      question:
        "If in a certain language, MADRAS is coded as NBESBT, how is BOMBAY coded in that code?",
      answer: "CPNCBZ",
    },
    {
      number: 61,
      question: "Take out of my skin ,I won't cry but you will . Who I am?",
      answer: "onion",
    },
    {
      number: 62,
      question:
        "Look in my face,I am somebody;Look in my back,I am nobody.What am I?",
      answer: "mirror",
    },
    {
      number: 63,
      question:
        "I speak without a mouth and hear without ears. I have no body, but I come alive with wind. What am I?",
      answer: "Echo",
    },
    {
      number: 64,
      question:
        "You can drop me from the tallest building and I'll be fine, but if you drop me in water I die. What am I?",
      answer: "Paper",
    },
    {
      number: 65,
      question: "How many letters are in the alphabet?",
      answer: "11",
    },
    {
      number: 66,
      question: "What breaks on the water but never on the land?",
      answer: "Waves ",
    },
  ];

  // firebase sa check karne ke liye
  const firestoremaisave = (duration) => {
    if (broCode !== "" && levelCode !== "") {
      const ref = db.collection("rollthedice").doc(broCode);
      ref.get().then((doc) => {
        if (doc.exists) {
          const value = doc.data();
          if (levelCode === "Hunt22") {
            if (value.statuss === false) {
              if (number < 37) {
                showDice();
                setTotal(number);
                setToDownload(value.pdf);
                alert("You are good to go!");
              } else
                alert(
                  "Sorry, you are late! 36 people have already answered correctly. Please try your luck in the next hunt"
                );
            } else
              alert(
                "Each user gets only 1 chance and you have exhausted your limit!"
              );
          } else alert("Please enter the correct Level code!");
        } else alert("Please enter the correct Bro code!");
      });
    } else alert("Please fill the fields marked with *");
  };

  const showDice = () => {
    document.querySelector(".rollForm").classList.add("d-none");
    document.querySelector(".rollDice").classList.remove("d-none");
  };

  const calcFirstDieNumber = (length) => {
    const total = riddles[length].number;

    const secondNum = total % 10;
    const firstNum = (total - secondNum) / 10;

    return firstNum;
  };

  const calcSecondDieNumber = (length) => {
    const total = riddles[length].number;

    const secondNum = total % 10;

    return secondNum;
  };

  const [riddleQuestion, setQuestion] = useState(null);
  const [riddleAnswer, setAnswer] = useState(null);
  const [riddleFile, setFile] = useState(null);

  if (diceCount === 2) {
    setTimeout(() => {
      document.querySelector(".rollDice").classList.add("d-none");
      document.querySelector(".rollRiddles").classList.remove("d-none");

      riddles.forEach((riddle) => {
        let number =
          calcFirstDieNumber(diceTotal) * 10 + calcSecondDieNumber(diceTotal);

        if (riddle.number === number) {
          setQuestion(riddle.question);
          setAnswer(riddle.answer);
          setFile(riddle.file);
        }
      });
    }, 2000);
  }

  const [userAnswer, setUserAnswer] = useState(null);

  const checkAnswer = async () => {
    if (userAnswer !== "") {
      if (userAnswer.toLowerCase() === riddleAnswer.toLowerCase()) {
        await db.collection("rollthedice").doc(broCode).update({
          statuss: true,
        });
        await db.collection("winnersofrollthedice").doc(broCode).set({
          broCode: broCode,
          question: riddleQuestion,
          answer: riddleAnswer,
          userAnswer: userAnswer,
        });
        alert(
          "Correct answer! A PDF file has been downloaded. Kindly refer to it for level 6."
        );

        var link = document.createElement("a");

        link.setAttribute("download", "");
        switch (pdfToDownload.charAt(3)) {
          case "1":
            link.href = PDF1;
            break;
          case "2":
            link.href = PDF2;
            break;
          case "3":
            link.href = PDF3;
            break;
          case "4":
            link.href = PDF4;
            break;
          case "5":
            link.href = PDF5;
            break;
          default:
            break;
        }
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.location.reload(true);
      } else alert("Incorrect!");
    } else alert("Please enter your answer!");
  };

  return (
    <React.Fragment>
      <div className="rollTheDiceCont">
        <div className="rollForm">
          <form>
            <div class="form-group">
              <label htmlFor="level3Code">Level Code:*</label>
              <input
                type="text"
                className="form-control"
                id="level3Code"
                onChange={(event) => setLevelCode(event.target.value)}
              />
            </div>
            <div class="form-group">
              <label htmlFor="userCode">Bro Code:*</label>
              <input
                type="text"
                className="form-control"
                id="userCode"
                onChange={(event) => setBroCode(event.target.value)}
              />
            </div>
            {/* <div class="form-group">
              <label htmlFor="broPdf">Bro PDF:*</label>
              <select
                className="form-control"
                id="broPdf"
                onChange={(event) => setBroPdf(event.target.value)}
              >
                <option value="PDF1">PDF1</option>
                <option value="PDF2">PDF2</option>
                <option value="PDF3">PDF3</option>
                <option value="PDF4">PDF4</option>
                <option value="PDF5">PDF5</option>
              </select>
            </div>
            <div class="form-group">
              <label htmlFor="broAudio">Bro Audio:*</label>
              <select
                className="form-control"
                id="broAudio"
                onChange={(event) => setBroAudio(event.target.value)}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div> */}
            <div class="form-group">
              <button
                type="button"
                onClick={firestoremaisave}
                className="form-control btn btn-primary"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="rollDice d-none">
          <div>
            <h4 className="mb-5">Roll both the dice-</h4>
            <Dice
              onRoll={(value) => {
                document.querySelector(".riddleNo").innerText =
                  value + document.querySelector(".riddleNo").innerText;
                disableFirst(true);
                setCount(++diceCount);
              }}
              size={100}
              cheatValue={calcFirstDieNumber(diceTotal)}
              rollingTime={3000}
              disabled={firstDice ? true : false}
            />
            <Dice
              onRoll={(value) => {
                document.querySelector(".riddleNo").innerText =
                  document.querySelector(".riddleNo").innerText + value;
                disableSecond(true);
                setCount(++diceCount);
              }}
              size={100}
              cheatValue={calcSecondDieNumber(diceTotal)}
              rollingTime={3000}
              disabled={secondDice ? true : false}
            />
          </div>
        </div>
        <div className="rollRiddles d-none">
          <h4 className="mt-5 d-none">
            You need to solve riddle number: <span className="riddleNo"></span>
          </h4>
          <h6 className="riddleQuestion mb-4">{riddleQuestion}</h6>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Your answer"
              onChange={(event) => setUserAnswer(event.target.value)}
            />
          </div>
          <div className="form-group">
            <button
              className="btn btn-primary form-control"
              onClick={checkAnswer}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RollTheDice;
