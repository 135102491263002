import React, { useRef } from "react";
import { useState } from "react";
import "../SignUpComponents/SignUpComponent.css";
import { FormControl } from "react-bootstrap";
import {
  Button,
  Card,
  Form,
  CardBody,
  FormGroup,
  Alert,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import "./LogInStyles.css";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../BreadcrumbComponent/BreadcrumbComponent";
import HeadingTitle from "../HeaderComponents/HeaderTitle";
import { Helmet } from "react-helmet";

function LogInComponent() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, signupWithGoogle } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history.push("/");
    } catch {
      setError("Failed to Login");
    }
    setLoading(false);
  }

  async function handleSubmitWithGoogle(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await signupWithGoogle();
      history.push("/");
    } catch {
      setError("Failed to Login");
    }
    setLoading(false);
  }

  const [activeTab, setActiveTab] = useState("2");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    // <React.Fragment>
    //   <Helmet>
    //     <title>Log In | TechnoHub BITD</title>
    //   </Helmet>
    //   <HeadingTitle heading="Log In" image="login.jpg" />
    //   <div className="login-container">
    //     <Breadcrumb>
    //       <BreadcrumbItem icon="fas fa-home" title="Home" path="/" />
    //       <BreadcrumbItem
    //         icon="fas fa-sign-in-alt"
    //         title="Log In"
    //         status="active"
    //       />
    //     </Breadcrumb>
    //     <div className="container">
    //       <Card className="login-card">
    //         <CardBody>
    //           <Nav tabs className="nav-fill">
    //             <NavItem>
    //               <Link
    //                 to="/signup"
    //                 style={{ color: "inherit", textDecoration: "none" }}
    //               >
    //                 <NavLink
    //                   className={classnames({ active: activeTab === "1" })}
    //                   onClick={() => {
    //                     toggle("1");
    //                   }}
    //                   style={{
    //                     backgroundColor: "#E6E6E6",
    //                     backgroundImage:
    //                       "linear-gradient(to right, #E6E6E6 , #F2F5F3)",
    //                     color: "darkgray",
    //                     fontWeight: "bold",
    //                   }}

    //                   // href="/signup"
    //                 >
    //                   Sign Up
    //                   {/* Sign Up */}
    //                 </NavLink>
    //               </Link>
    //             </NavItem>
    //             <NavItem>
    //               <Link
    //                 to="/login"
    //                 style={{ color: "black", textDecoration: "none" }}
    //               >
    //                 <NavLink
    //                   className={classnames({ active: activeTab === "2" })}
    //                   // href="/login"
    //                   style={{ fontWeight: "bold" }}
    //                   onClick={() => {
    //                     toggle("2");
    //                   }}
    //                 >
    //                   Log In
    //                 </NavLink>
    //               </Link>
    //             </NavItem>
    //           </Nav>
    //           {error && <Alert color="danger">{error}</Alert>}
    //           <Form onSubmit={handleSubmit} className="login-form">
    //             <FormGroup id="email">
    //               <FormControl
    //                 type="email"
    //                 ref={emailRef}
    //                 placeholder="Enter Your Email"
    //                 required
    //               />
    //             </FormGroup>
    //             <FormGroup id="password">
    //               <FormControl
    //                 type="password"
    //                 ref={passwordRef}
    //                 placeholder="Enter Your Password"
    //                 required
    //               />
    //             </FormGroup>
    //             <div style={{ textAlign: "right", marginTop: "20px" }}>
    //               <Link to="/forgot-password">Forgot your password?</Link>
    //             </div>
    //             <Button disabled={loading} type="submit">
    //               <i className="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Log In
    //             </Button>
    //           </Form>
    //           <Row>
    //             <Col>
    //               <hr class="solid" />
    //             </Col>
    //             <Col style={{ minWidth: "160px", textAlign: "center" }}>
    //               Or Connect With
    //             </Col>
    //             <Col>
    //               <hr class="solid" />
    //             </Col>
    //           </Row>
    //           <Row></Row>
    //         </CardBody>
    //         <Button
    //           onClick={handleSubmitWithGoogle}
    //           type="button"
    //           className="login_signInButton"
    //           color="info"
    //         >
    //           <i className="fab fa-google"></i>&nbsp;&nbsp;Continue with Google
    //         </Button>
    //         {/* </Row> */}
    //       </Card>
    //     </div>
    //     {/* </div> */}
    //   </div>
    // </React.Fragment>
    <React.Fragment>
      <Helmet>
        <title>Log In | TechnoHub BITD</title>
      </Helmet>
      <div className="signUpCont">
        <div className="image"></div>
        <div className="form">
          <h2 className="title" style={{textAlign:"center"}}>Login</h2>
          {error && (
            <Alert color="danger" style={{ marginBottom: "2em" }}>
              {error}
            </Alert>
          )}
          <form action="POST" onSubmit={handleSubmit}>
            <div className="inputGroup">
              <div className="input">
                <input
                  type="email"
                  name="email"
                  ref={emailRef}
                  id="email"
                  placeholder="E-mail ID*"
                  required
                />
                <label htmlFor="name">E-mail ID*</label>
              </div>
            </div>
            <div className="inputGroup">
              <div className="input">
                <input
                  type="password"
                  name="password"
                  ref={passwordRef}
                  id="password"
                  placeholder="Password*"
                  required
                />
                <label htmlFor="password">Password*</label>
              </div>
            </div>
            <div className="inputGroup signupforgot twoInputs input-group">
              <div className="link">
                Not a member? <Link to="/signup">Signup</Link> now.
              </div>
              <div className="forgot margin-top-20">
                   <Link to="/forgot-password">Forgot your password?</Link>
              </div>
            </div>
            
            <div className="inputGroup twoInputs input-group">
              <button type="submit" disabled={loading}>
                Login
              </button>
              <button
                type="button"
                className="google"
                onClick={handleSubmitWithGoogle}
              >
                <i className="fab fa-google"></i>&nbsp;&nbsp;Continue with
                Google
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LogInComponent;
